import React from 'react';
import Router from 'next/router';

// Loader Bar
import NProgress from 'nprogress';

// Redux Saga
import withReduxSaga from 'next-redux-saga';
import { END } from 'redux-saga';
import wrapper from '@store';

// Components
import { StyledToasts, Modal } from '@components';

// Styles and Themes
import { ThemeProvider } from 'styled-components';
import * as tokens from '@theme';
import { GlobalStyle } from '@theme';

import { getToken } from '@utils';
import { getUserPropertiesByToken } from '@store/ducks/user/actions';

// Monitoring
import { ClientLogger } from 'src/services/monitoring/clientLogger';

// CSS
import 'react-sortable-tree/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'public/assets/css/nprogress.css';

// Init Monitoring
ClientLogger.init();

// Loader NProgress
NProgress.configure({
  showSpinner: false,
  easing: 'ease',
  speed: 500,
  trickle: true,
  trickleSpeed: 200,
});

// Loader NProgress
Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

// Loader NProgress
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

const App = ({ Component, pageProps }) => (
  <ThemeProvider theme={tokens}>
    <GlobalStyle theme={tokens} />
    <StyledToasts />
    <Modal />
    <div id="nprogress-container" />
    <Component {...pageProps} />
  </ThemeProvider>
);

App.getInitialProps = async ({ Component, ctx }) => {
  const token = getToken({ type: typeof window === 'undefined', ctx });

  if (token) {
    ctx.store.dispatch(getUserPropertiesByToken({ params: token }));
    ctx.store.dispatch(END);
    await ctx.store.sagaTask.toPromise();
  }

  return {};
};

export default wrapper.withRedux(withReduxSaga(App));
