import counter from './counter';
import dashboard from './dashboard';
import modal from './modal';
import pageSize from './pageSize';
import user from './user';
import plan from './plan';
import group from './group';

export default {
  counter,
  dashboard,
  modal,
  pageSize,
  user,
  plan,
  group,
};
