import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  counter: 0,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.counterReducer,
      };
    case types.INCREMENT:
      return { ...state, counter: state.counter + 1 };
    case types.INCREMENT_IF_ODD:
      return {
        ...state,
        counter: state.counter % 2 !== 0 ? state.counter + 1 : state.counter,
      };
    case types.DECREMENT:
      return { ...state, counter: state.counter - 1 };
    default:
      return state;
  }
}

export { actions, sagas };
