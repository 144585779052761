import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createWrapper } from 'next-redux-wrapper';

import { ToastMiddleware } from '@components';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { ClientLogger } from 'src/services/monitoring/clientLogger';

import rootSaga from './ducks/rootSaga';
import reducers from './ducks/rootReducer';

export const configureStore = () => {
  // 1: Create the middleware
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    combineReducers(reducers),
    composeWithDevTools(
      applyMiddleware(sagaMiddleware, ToastMiddleware),
      ...ClientLogger.storeWatchers
    )
  );

  // 3: Run your sagas on server
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

const wrapper = createWrapper(configureStore, {
  debug: false,
});

export default wrapper;
