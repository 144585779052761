import { fork, all } from 'redux-saga/effects';
import { sagas as counterSagas } from './counter';
import { sagas as dashboardSagas } from './dashboard';
import { sagas as modalSagas } from './modal';
import { sagas as pageSizeSagas } from './pageSize';
import { sagas as userSagas } from './user';
import { sagas as planSagas } from './plan';
import { sagas as groupSagas } from './group';

const allSagas = [
  ...counterSagas,
  ...dashboardSagas,
  ...modalSagas,
  ...pageSizeSagas,
  ...userSagas,
  ...planSagas,
  ...groupSagas,
];

// start all sagas in parallel
export default function* rootSaga() {
  yield all(allSagas.map((saga) => fork(saga)));
}
